<template>
  <!-- <div class="mx-auto my-auto text-grey1">
    You do not have permission to view this page
  </div> -->
  <div class="flex flex-grow">
    <blurred-meeting> </blurred-meeting>
  </div>
</template>

<script>
import BlurredMeeting from './BlurredMeeting.vue';

export default {
  name: 'Error',
  components: {
    BlurredMeeting,
  },
  props: {
    text: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      delay: 1000,
    };
  },

  mounted() {
    // notification bar delay
    setTimeout(this.showDelayedNotification(), this.delay);
  },
  methods: {
    showDelayedNotification() {
      this.$notification(this.text, {
        closable: false,
        type: 'error',
        errorMsg: 'error',
      });
    },
  },
};
</script>
