<template>
  <div class="flex flex-grow">
    <blurred-meeting> </blurred-meeting>
  </div>
</template>

<script>
import BlurredMeeting from './BlurredMeeting.vue';

export default {
  name: 'Forbidden',
  components: {
    BlurredMeeting,
  },
  data() {
    return {
      delay: 1000,
    };
  },
  mounted() {
    // notification bar delay
    setTimeout(this.showDelayedNotification(), this.delay);
  },
  methods: {
    showDelayedNotification() {
      if (this.$gAuth.isAuthorized) {
        this.$notification(
          'Oops - meeting not found! Check url or poke host for access.',
          {
            closable: false,
            type: 'error',
            errorMsg: 'forbidden-signedin',
          }
        );
      } else {
        this.$notification(
          [
            { link: { text: 'Sign in to Meetric', to: 'signin' } },
            { text: ' to take notes for any meeting.' },
          ],
          {
            closable: false,
            type: 'error',
            errorMsg: 'forbidden-signedout',
          }
        );
      }
    },
  },
};
</script>
